:root {
  --body-background-color: white;

  --app-container-background-color: rgb(246, 246, 239);
  --app-container-color: black;

  --story-cotainer-border-color: #f0f0f0;
  --story-border-color: gainsboro;

  --link-card-text-color: gray;

  --input-text-color: black;

  --link-button-color: #007bff;

  --op-indicator-border-color: rgb(69, 151, 240);
}

@media (prefers-color-scheme: dark) {
  :root {
    --body-background-color: rgb(21, 32, 43);

    --app-container-background-color: rgb(25, 39, 52);
    --app-container-color: white;

    --story-cotainer-border-color: rgb(47, 48, 49);
    --story-border-color: rgb(80, 76, 76);

    --link-card-text-color: rgb(69, 151, 240);

    --input-text-color: white;

    --op-indicator-border-color: rgb(69, 151, 240);
  }
}

body {
  background-color: var(--body-background-color);
  height: '100%';
}

.item-page {
  background-color: var(--app-container-background-color);
}

.header-sticky {
  background-color: var(--app-container-background-color);
}

#root {
  height: '100%';
}

.app-container {
  /* user-select: none; // only for frontpage? */
  background-color: var(--app-container-background-color);
  color: var(--app-container-color);
  max-width: 650px;
  touch-action: manipulation;
}

.story--container {
  background-color: var(--app-container-background-color);
  border-bottom: 0.1rem solid var(--story-cotainer-border-color);
}
.story--comments {
  border-left: 0.1rem solid var(--story-cotainer-border-color);
}

.story--container a {
  color: inherit;
  text-decoration: inherit;
}

.story--info {
  font-size: x-small;
}

.item--hn-text p:last-of-type {
  margin-bottom: 0;
}
.item--hn-text p:first-of-type {
  margin-top: 1rem;
}
.item--hn-text pre {
  color: inherit;
}

$border-colors: (
  orange: rgb(252, 183, 127),
  red: rgb(230, 151, 151),
  blue: rgba(102, 102, 221, 0.801),
  gray: rgb(189, 186, 186),
  purple: rgb(208, 151, 230),
  green: rgba(23, 216, 184, 0.616)
);

@each $name, $color in $border-colors {
  .level-border-#{$name} {
    border-left: 0.16rem solid $color;
  }
}

.link-card {
  border: 0.05rem solid var(--story-border-color);
  border-radius: 0.7em;
  color: var(--link-card-text-color);
}

.link-card--text {
  border-left: 0.05rem solid var(--story-border-color);

  color: var(--link-card-text-color);
  text-decoration: none;
}

.hnr-floating-button {
  /* floating css */
  position: fixed;
  bottom: 30px;
  right: 10px;
  z-index: 99;

  /* styling */
  width: 2.5em;
  height: 2.5em;
  border-radius: 2.5em;
  font-size: 1.5em;
  color: white;
}

/* utils */
.clickable {
  cursor: pointer;
}

.hnr-inherit-color {
  color: inherit;
}

@each $side in top, bottom, right {
  .h-border-#{$side} {
    border-#{$side}: 0.01em solid var(--story-border-color);
  }
}

.search-bar {
  background-color: var(--app-container-background-color) !important;
  color: var(--input-text-color) !important;
  border-radius: 0.3rem;
  border-color: var(--story-border-color);
}

.search-bar-inactive {
  height: 1.5em;
  filter: brightness(90%);
}

.hnr-blue {
  color: var(--link-button-color);
}

.op-indicator {
  border: solid var(--op-indicator-border-color) 0.1px;
}
